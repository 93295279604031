// Autor: Vojtěch Randýsek, 2019, VUT FIT

import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { PagerPipe } from './pager.pipe';
import { RolePipe } from './role.pipe';
import { ReservationDurationPipe } from './reservation-duration.pipe';

@NgModule({
    declarations: [
        FilterPipe,
        PagerPipe,
        RolePipe,
        ReservationDurationPipe
    ],
    imports: [
    ],
    exports: [
        FilterPipe,
        PagerPipe,
        RolePipe,
        ReservationDurationPipe
    ],
})
export class PipesModule { }
