import { Component } from '@angular/core';
import { PagerPipe } from 'src/app/core/pipes/pager.pipe';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LongTermTableItem } from './long-term-table-time';
import { ConfiguratorBase } from 'src/app/core/configurator-base';
import { LongTermReservationApi } from 'src/app/core/api/long-term-reservations/long-term-reservation-api.service';
import { DayOfWeekService } from 'src/app/core/day-of-week.service';
import { ReservationApi } from 'src/app/core/api/reservation/reservation-api.service';
import { ConfirmationService } from 'src/app/confirmation-popup/confirmation.service';
import { ReservationService } from '../../reservation.service';
import { LoginService } from 'src/app/core/api/login/login.service';
import { Roles } from 'src/app/core/api/login/roles.enum';
import { Config } from 'src/assets/config';

@Component({
    selector: 'tco-long-term',
    templateUrl: './long-term.component.html',
    styleUrls: ['./long-term.component.scss']
})
export class LongTermComponent extends ConfiguratorBase {
    Roles = Roles;

    reservations: LongTermTableItem[] = [];
    selectedTableRow: LongTermTableItem;

    noteFilter: string;
    dayFilter: string;
    fromFilter: string;
    untilFilter: string;
    courtFilter: string;
    createdByFilter: string;

    showPager = true;

    constructor(pager: PagerPipe, private filterPipe: FilterPipe, private api: LongTermReservationApi,
        private dayOfWeekService: DayOfWeekService, private modalService: NgbModal, private reservationApi: ReservationApi,
        private confirm: ConfirmationService, private reservationService: ReservationService, public login: LoginService) {
        super(pager);
    }

    async refresh() {
        const reservationDTOs = await this.api.getLongTermReservations();
        if (!reservationDTOs) {
            return;
        }

        this.reservations = reservationDTOs.map<LongTermTableItem>((dto) => {
            const ti = new LongTermTableItem();
            ti.id = dto.id;
            ti.dayOfWeek = this.dayOfWeekService.getDayNameByNumber(dto.dayOfWeek);
            ti.court = (Number.parseInt(dto.courtId) - Config.courtOffset).toString(10);
            ti.note = dto.note;
            ti.createdBy = dto.createdBy;

            let idx = dto.fromTime.lastIndexOf(':');
            ti.from = dto.fromTime.substring(0, idx < 0 ? dto.fromTime.length : idx);

            idx = dto.untilTime.lastIndexOf(':');
            ti.until = dto.untilTime.substring(0, idx < 0 ? dto.fromTime.length : idx);

            return ti;
        }).sort((a, b) => {
            let res = 0;
            if (b.dayOfWeek && a.dayOfWeek) {
                res = this.dayOfWeekService.getDayNumber(a.dayOfWeek) - this.dayOfWeekService.getDayNumber(b.dayOfWeek);
            }

            if (res === 0) {
                res = a.from.localeCompare(b.from);
            }

            if (res === 0) {
                res = a.until.localeCompare(b.until);
            }

            return res;
        });

        this.filterData();
    }

    filterData() {
        let reservationsFiltered = this.filterPipe.transform(this.reservations, 'dayOfWeek', this.dayFilter);
        reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'note', this.noteFilter);
        reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'from', this.fromFilter);
        reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'until', this.untilFilter);
        reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'court', this.courtFilter);
        reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'createdBy', this.createdByFilter);

        this.initPager(reservationsFiltered);
        this.dataFiltered = reservationsFiltered;
        this.paginationChanged(this.currentPage);
    }

    async onRowDelete(reservation: LongTermTableItem) {
        let res = await this.confirm.presentConfirmationModal();
        if (!res) {
            return;
        }

        await this.reservationApi.deleteLongTermReservation(reservation.id);
        this.reservationService.reservationChanged.emit();
        await this.refresh();
    }
}
