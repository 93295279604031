import { Injectable } from '@angular/core';
import { ApiProviderBase } from '../api-provider-base';
import { HttpService } from '../http-service';
import { KPIUserDTO } from './kpi-user.dto';
import { KPIBarDTO } from './kpi-bar.dto';
import { KPIAdminDTO } from './kpi-admin.dto';

@Injectable({ providedIn: 'root' })
export class KPIApi extends ApiProviderBase {
    constructor(httpService: HttpService) {
        super(httpService);
    }
    public getUserKPI(): Promise<KPIUserDTO> {
        return this.callGetPromise<KPIUserDTO>('kpi-user.php');
    }

    public getBarKPI(): Promise<KPIBarDTO> {
        return this.callGetPromise<KPIBarDTO>('kpi-bar.php');

    }

    public getAdminKPI(): Promise<KPIAdminDTO> {
        return this.callGetPromise<KPIAdminDTO>('kpi-admin.php');

    }
}
