import { Component } from '@angular/core';
import { Roles } from '../core/api/login/roles.enum';
import { LoginService } from '../core/api/login/login.service';
import { UserService } from '../core/user.service';
import { ToastService } from '../core/toast.service';
import { UserApi } from '../core/api/user/user-api.service';
import { UserDTO } from '../core/api/account/user-dto';
import { User } from '../reservations/user';

@Component({
  selector: 'tco-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent {
  public Roles = Roles;
  public pinCode: string;
  editMode = false;
  userEditModel: User;

  constructor(public login: LoginService, public userService: UserService,
    public toastService: ToastService, private userApi: UserApi) { }

  public async logout() {
    await this.login.logOut();
  }

  async editUserClicked() {
    this.userEditModel = { ...this.userService.user };
    this.userEditModel.birthDate = new Date(this.userService.user.birthDate);
    this.editMode = true;
  }

  async confirm() {
    const dto = new UserDTO();
    dto.id = this.userEditModel.id;
    dto.firstName = this.userEditModel.firstName;
    dto.lastName = this.userEditModel.lastName;
    dto.birthDate = this.userEditModel.birthDate;
    dto.email = this.userEditModel.email;
    dto.phoneNumber = this.userEditModel.phoneNumber;
    dto.reservationEmailNotificationReceiver = this.userEditModel.reservationEmailNotificationReceiver ? '1' : '0';
    dto.reservationSmsNotificationReceiver = this.userEditModel.reservationSmsNotificationReceiver ? '1' : '0';

    await this.userApi.modifyUser(dto);
    this.userService.refreshUser();
    this.editMode = false;
  }
}
