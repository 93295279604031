import { Injectable } from '@angular/core';
import { RegistrationDataDTO } from './registration-data.dto';
import { ApiProviderBase } from '../api-provider-base';
import { HttpService } from '../http-service';
import { UserDTO } from './user-dto';
import { RegistrationResponseDTO } from './registration-response.dto';

@Injectable({ providedIn: 'root' })
export class AccountApi extends ApiProviderBase {
    constructor(httpService: HttpService) {
        super(httpService);
    }

    public getUser(): Promise<UserDTO> {
        return this.callGetPromise<UserDTO>(`account.php`);
    }

    public registerUser(data: RegistrationDataDTO): Promise<RegistrationResponseDTO> {
        return this.callPostPromise<RegistrationDataDTO>(`account.php`, data);
    }

}
