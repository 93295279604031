import { Component } from '@angular/core';
import { ConfiguratorBase } from 'src/app/core/configurator-base';
import { PagerPipe } from 'src/app/core/pipes/pager.pipe';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';
import { ReservationService } from '../reservation.service';
import { AllReservationTableItem } from './all-reservation-table-item';
import { AllReservationsApi } from 'src/app/core/api/all-reservations/all-reservations-api.service';
import { Config } from 'src/assets/config';

@Component({
  selector: 'tco-all-reservations-list',
  templateUrl: './all-reservations-list.component.html',
  styleUrls: ['./all-reservations-list.component.scss']
})
export class AllReservationsListComponent extends ConfiguratorBase {
  reservations: AllReservationTableItem[] = [];
  selectedTableRow: AllReservationTableItem;

  idFilter: string;
  paidByOrderNumber: string;
  nameFilter: string;
  dateFilter: string;
  fromFilter: string;
  untilFilter: string;
  courtFilter: string;
  priceFilter: string;

  showPager = true;

  constructor(pager: PagerPipe, private filterPipe: FilterPipe,
    private api: AllReservationsApi, reservationService: ReservationService) {
    super(pager);
    reservationService.reservationChanged.subscribe(() => this.refresh());
  }

  async refresh() {
    const reservationDTOs = await this.api.getAllReservations();
    if (!reservationDTOs) {
      return;
    }
    this.reservations = reservationDTOs.map<AllReservationTableItem>((dto) => {
      const ti = new AllReservationTableItem();
      ti.id = dto.id;
      ti.paidByOrderNumber = dto.paidByOrderNumber;
      ti.date = dto.date;
      ti.court = (Number.parseInt(dto.courtId) - Config.courtOffset).toString(10);
      ti.userName = dto.userNameFull;
      ti.price = dto.price;

      let idx = dto.fromTime.lastIndexOf(':');
      ti.from = dto.fromTime.substring(0, idx < 0 ? dto.fromTime.length : idx);

      idx = dto.untilTime.lastIndexOf(':');
      ti.until = dto.untilTime.substring(0, idx < 0 ? dto.fromTime.length : idx);

      return ti;
    }).sort((a, b) => {
      let res = b.date.localeCompare(a.date);
      if (res === 0) {
        res = a.from.localeCompare(b.from);
      }
      if (res === 0) {
        res = a.until.localeCompare(b.until);
      }
      return res;
    });

    this.filterData();
  }

  filterData() {
    let reservationsFiltered = this.filterPipe.transform(this.reservations, 'date', this.dateFilter, this.filterPipe.convertToLocaleDate);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'id', this.idFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'paidByOrderNumber', this.paidByOrderNumber);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'userName', this.nameFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'from', this.fromFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'until', this.untilFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'court', this.courtFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'price', this.priceFilter);

    this.initPager(reservationsFiltered);
    this.dataFiltered = reservationsFiltered;
    this.paginationChanged(this.currentPage);
  }
}
