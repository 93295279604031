import { Injectable } from '@angular/core';
import { ApiProviderBase } from '../api-provider-base';
import { HttpService } from '../http-service';
import { SubstituteReservationDTO } from './substitute-reservation.dto';

@Injectable({ providedIn: 'root' })
export class SubstituteReservationApi extends ApiProviderBase {
    constructor(httpService: HttpService) {
        super(httpService);
    }

    public getSubstituteReservations(): Promise<SubstituteReservationDTO[]> {
        const url = `substitutes.php`;
        return this.callGetPromise<SubstituteReservationDTO[]>(url);
    }
}
