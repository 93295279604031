import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DatePickerI18N extends NgbDatepickerI18n {
    I18N_VALUES = {
        weekdays: ['po', 'út', 'st', 'čt', 'pá', 'so', 'ne'],
        months: ['led', 'úno', 'bře', 'dub', 'kvě', 'čvn', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
        // other languages you would support
    };

    constructor() {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return this.I18N_VALUES.weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
        return this.I18N_VALUES.months[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}
