import { Injectable, EventEmitter } from '@angular/core';
import { LoginApi } from './login-api.service';
import { CredentialsDTO } from './credentials.dto';
import { TokenDTO } from './token.dto';
import { Roles } from './roles.enum';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    loaded: Promise<boolean>;

    role: Roles = Roles.Unauthorized;
    onUserChange = new EventEmitter();

    constructor(private loginApi: LoginApi) {
        this.initUserRoleFromLocalStorage();
    }

    async logIn(pinCode: string) {
        const dto = new CredentialsDTO();
        dto.pinCode = pinCode;

        const token = await this.loginApi.getToken(dto);

        this.setAuthToken(token);
        this.setRole(token.role);
        this.onUserChange.emit();
    }

    logOut() {
        this.role = Roles.Unauthorized;
        this.removeAuthToken();
        location.reload();
    }

    // if token exists, user is logged in
    isUserLoggedIn(): boolean {
        const token = this.getAuthToken();
        if (token != null) {
            return true;
        }
        return false;
    }

    getAuthToken(): string {
        const token = localStorage.getItem('userAuthToken');
        return token;
    }

    private setAuthToken(token: TokenDTO): void {
        localStorage.setItem('userAuthToken', token.jwt);
    }

    private removeAuthToken(): void {
        localStorage.removeItem('userAuthToken');
        localStorage.removeItem('userRole');
    }

    private initUserRoleFromLocalStorage() {
        const roleString = localStorage.getItem('userRole');
        if (roleString) {
            this.setRole(roleString);
            this.onUserChange.emit();
        }
    }

    setRole(role: string) {
        if (role === '1') {
            this.role = Roles.User;
        } else if (role === '2') {
            this.role = Roles.Admin;
        } else if (role === '3') {
            this.role = Roles.SuperAdmin;
        } else {
            this.role = Roles.Unauthorized;
        }

        localStorage.setItem('userRole', role);
    }
}

