export class LongTermReservationModificationDTO {
    id: string;
    reservationId: string;
    reservationDate: string;
    canceledOnDate: string;
    note: string = null;
    modified: string;
    modifiedFromTime: string = null;
    modifiedUntilTime: string = null;
    modifiedByName: string;
    modifiedById: string;
    originalFromTime: string;
    originalUntilTime: string;
    originalDay: number;
    courtCode: string;
    originalNote: string;
}
