import { Injectable } from '@angular/core';
import { CourtDTO } from './court.dto';
import { ApiProviderBase } from '../api-provider-base';
import { HttpService } from '../http-service';

@Injectable({ providedIn: 'root' })
export class CourtApi extends ApiProviderBase {
    constructor(httpService: HttpService) {
        super(httpService);
    }

    public getCourtsForCurrentSeason(): Promise<CourtDTO[]> {
        return this.callGetPromise<CourtDTO[]>('current-courts.php');
    }
}
