import { Injectable } from '@angular/core';
import { CredentialsDTO } from './credentials.dto';
import { TokenDTO } from './token.dto';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/assets/config';

@Injectable({ providedIn: 'root' })
export class LoginApi {
    constructor(private httpClient: HttpClient) { }

    public getToken(data: CredentialsDTO): Promise<TokenDTO> {
        return this.httpClient.post<any>(`${Config.apiBaseUrl}/login.php`, data)
            .toPromise<TokenDTO>();
    }

}
