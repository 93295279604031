export class Config {
    // static apiBaseUrl = 'https://tenisolomouc.eu/old/api';
    static apiBaseUrl = 'https://tenisolomouc.eu/api';

    static googleMapsApiKey = 'AIzaSyDIr9WOJ5fGvX7aIPpQW1URDubo2eeuLLE';

    // timetable config
    static startingHour = 7;
    static startingMinute = 0;
    static endHour = 21;
    static endMinute = 0;
    static intervalLengthMins = 30;
    static seasonStartDate: Date = new Date("2022-10-17");
    static seasonEndDate: Date = new Date("2023-4-14 23:59:59");

    // params
    static galleryImages = [
        '1gallery_1.jpg',
        '1gallery_2.jpg',
        '1gallery_3.jpg',
        '1gallery_4.jpg',
        '1gallery_5.jpg',
        '1gallery_6.jpg',
        '1gallery_7.jpg',
        '1gallery_8.jpg',
        '1gallery_10.jpg',
        '1gallery_11.jpg'
    ];

    static siteName = 'Tenis Centrum Olomouc s.r.o.';
    static homeSubtitle = 'Pronájem tenisových kurtů v zimním období';
    static mapLat = 49.593152;
    static mapLng = 17.261440;

    static barExists = false;

    static courtOffset = 12;
}
