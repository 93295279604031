import { Component, OnInit, Input } from '@angular/core';
import { Reservation } from 'src/app/reservations/reservations-tab/reservation';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/core/user.service';
// tslint:disable-next-line:max-line-length
import { LongTermReservationModificationApi } from 'src/app/core/api/long-term-reservation-modification/long-term-reservation-modification-api.service';
// tslint:disable-next-line:max-line-length
import { LongTermReservationModificationDTO } from 'src/app/core/api/long-term-reservation-modification/long-term-reservation-modification.dto';
import * as moment from 'moment';
import { Config } from 'src/assets/config';

@Component({
  selector: 'tco-modify-long-term',
  templateUrl: './modify-long-term.component.html',
  styleUrls: ['./modify-long-term.component.scss']
})
export class ModifyLongTermComponent implements OnInit {
  @Input() reservation: Reservation;
  @Input() timeUntil: string;
  @Input() timeFrom: string;
  @Input() date: Date;

  minTime = `${Config.startingHour}:${Config.startingMinute}`;
  maxTime = `${Config.endHour}:${Config.endMinute}`;

  note: string;
  newTimeUntil: string;
  newTimeFrom: string;
  public moment = moment;

  constructor(private activeModal: NgbActiveModal, private user: UserService, private api: LongTermReservationModificationApi) { }

  ngOnInit() {
  }

  async confirm() {
    let dto = new LongTermReservationModificationDTO();
    dto.reservationId = this.reservation.id;
    dto.reservationDate = moment(this.date).format('YYYY-MM-DD');
    dto.modified = '1';
    dto.modifiedById = this.user.user.id;
    dto.canceledOnDate = moment(new Date()).format('YYYY-MM-DD');
    dto.note = this.note;
    dto.modifiedFromTime = this.roundTime(this.newTimeFrom);
    dto.modifiedUntilTime = this.roundTime(this.newTimeUntil);
    await this.api.modifyLongTermReservation(dto);
    this.activeModal.close(); // resolve
  }

  cancel() {
    this.activeModal.dismiss(); // reject
  }

  private roundTime(time: string): string {
    let hours = Number(time.split(':')[0]);
    let minutes = Number(time.split(':')[1]);

    if (minutes === 0) {
      return `${hours}:${minutes}0`;
    }

    if (minutes === 30) {
      return `${hours}:${minutes}`;
    }

    if (minutes > 0 && minutes < 15) {
      return `${hours}:00`;
    }

    if (minutes > 15 && minutes < 45) {
      return `${hours}:30`;
    }

    if (minutes > 45 && minutes < 60) {
      return `${hours + 1}:00`;
    }
  }
}
