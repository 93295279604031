import { Component } from '@angular/core';
import { RegistrationCredentials } from './registration-credentials';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountApi } from '../core/api/account/account-api.service';
import { RegistrationDataDTO } from '../core/api/account/registration-data.dto';
import { PostRegistrationComponent } from '../post-registration/post-registration.component';
import { LoginService } from '../core/api/login/login.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
  data = new RegistrationCredentials();

  constructor(private modalService: NgbModal, private thisModal: NgbActiveModal, private accountApi: AccountApi,
    private loginService: LoginService) { }

  async onSubmit() {
    const dto = new RegistrationDataDTO();
    dto.firstName = this.data.firstName;
    dto.lastName = this.data.lastName;
    dto.birthDate = this.data.birthDate;
    dto.email = this.data.email;
    dto.phoneNumber = this.data.phoneNumber;
    dto.reservationEmailNotificationReceiver = this.data.reservationEmailNotificationReceiver;
    dto.reservationSmsNotificationReceiver = this.data.reservationSmsNotificationReceiver;

    let response = await this.accountApi.registerUser(dto);

    let modal = this.modalService.open(PostRegistrationComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true });
    modal.componentInstance.email = dto.email;
    modal.componentInstance.pinCode = response.pinCode;
    modal.componentInstance.mailSentSuccess = response.mailSent;

    await modal.result;

    this.loginService.logIn(response.pinCode);
    this.dismissModal();
  }

  dismissModal() {
    this.thisModal.close();
  }
}
