import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LongTermComponent } from './long-term.component';

@NgModule({
    declarations: [
        LongTermComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        PipesModule,
        NgbModule
    ],
    providers: [

    ],
    exports: [
        LongTermComponent
    ]
})
export class LongTermModule { }
