import { Config } from '../../../assets/config';
import { HttpService } from './http-service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ApiProviderBase {
    private baseUrl = Config.apiBaseUrl;

    constructor(private http: HttpService) { }

    protected callGetPromise<T>(resourceUrl: string): Promise<T> {
        return this.http.callGetPromise<T>(`${this.baseUrl}/${resourceUrl}`);
    }

    protected callPostPromise<TRequest>(resourceUrl: string, body: TRequest): Promise<any> {
        return this.http.callPostPromise<TRequest>(`${this.baseUrl}/${resourceUrl}`, body);
    }

    protected callPutPromise<TRequest>(resourceUrl: string, body: TRequest) {
        return this.http.callPutPromise<TRequest>(`${this.baseUrl}/${resourceUrl}`, body);
    }

    protected callDeletePromise<TRequest>(resourceUrl: string, body?: TRequest) {
        return this.http.callDeletePromise<TRequest>(`${this.baseUrl}/${resourceUrl}`, body);
    }
}
