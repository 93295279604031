import { Injectable } from '@angular/core';
import { User } from '../reservations/user';
import { AccountApi } from './api/account/account-api.service';
import { LoginService } from './api/login/login.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    user: User;

    constructor(private accountApi: AccountApi, private loginService: LoginService) {
        loginService.onUserChange.subscribe(() => this.refreshUser());
    }

    async refreshUser() {
        if (this.loginService.isUserLoggedIn()) {
            this.user = await this.getCurrentUser();
        }
    }

    private async getCurrentUser() {
        const userDTO = await this.accountApi.getUser();
        const user = new User();
        user.firstName = userDTO.firstName;
        user.role = userDTO.role;
        user.lastName = userDTO.lastName;
        user.birthDate = userDTO.birthDate;
        user.id = userDTO.id;
        user.email = userDTO.email;
        user.phoneNumber = userDTO.phoneNumber;
        user.reservationEmailNotificationReceiver = userDTO.reservationEmailNotificationReceiver === '1';
        user.reservationSmsNotificationReceiver = userDTO.reservationSmsNotificationReceiver === '1';

        return user;
    }
}
