import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerI18n, NgbToastModule, NgbDateParserFormatter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReservationsTabComponent } from './reservations/reservations-tab/reservations-tab.component';
import { UsersTabComponent } from './reservations/users-tab/users-tab.component';
import { ConfigurationTabComponent } from './reservations/configuration-tab/configuration-tab.component';
import { LongTermModule } from './reservations/configuration-tab/long-term/long-term.module';
import { RegistrationComponent } from './registration/registration.component';
import { PipesModule } from './core/pipes/pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReservationsListComponent } from './reservations/reservations-list/reservations-list.component';
import { ReservationCreatorComponent } from './reservations/reservations-tab/reservation-creator/reservation-creator.component';
import { TimetableComponent } from './timetable/timetable.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { DatePickerI18N } from './core/datepicker-i18n.service';
import { ToastsContainerComponent } from './core/toasts-container.component';
import { AllReservationsListComponent } from './reservations/all-reservations-list/all-reservations-list.component';
// tslint:disable-next-line:max-line-length
import { ModifiedLongTermReservationModule } from './reservations/configuration-tab/modifed-long-term-reservations/modified-long-term-reservations.module';
import { AgmCoreModule } from '@agm/core';
import { Config } from 'src/assets/config';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { LoginComponent } from './login/login.component';
import { ModifyLongTermComponent } from './timetable/modify-long-term/modify-long-term.component';
import { UserEditorComponent } from './reservations/users-tab/user-editor/user-editor.component';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { PostRegistrationComponent } from './post-registration/post-registration.component';
import { BarReservationsListComponent } from './reservations/bar-reservations-list/bar-reservations-list.component';

import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { LOCALE_ID } from '@angular/core';
import { NgbDateCustomParserFormatter } from './core/datepicker-formatter';
import { KpiComponent } from './kpi/kpi.component';
import { SubstitutesComponent } from './substitutes/substitutes.component';

registerLocaleData(localeCs, 'cs');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ReservationsComponent,
    ReservationsTabComponent,
    ReservationsListComponent,
    UsersTabComponent,
    ConfigurationTabComponent,
    RegistrationComponent,
    ReservationCreatorComponent,
    TimetableComponent,
    ToastsContainerComponent,
    AllReservationsListComponent,
    UserDetailComponent,
    LoginComponent,
    ModifyLongTermComponent,
    UserEditorComponent,
    ConfirmationPopupComponent,
    PostRegistrationComponent,
    BarReservationsListComponent,
    KpiComponent,
    SubstitutesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    AngularFontAwesomeModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    LongTermModule,
    PipesModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    NgbToastModule,
    ModifiedLongTermReservationModule,
    AgmCoreModule.forRoot({
      apiKey: Config.googleMapsApiKey
    }),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'cs-CS' },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDatepickerI18n, useClass: DatePickerI18N },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    RegistrationComponent,
    ReservationCreatorComponent,
    ModifyLongTermComponent,
    UserEditorComponent,
    ConfirmationPopupComponent,
    PostRegistrationComponent
  ]
})
export class AppModule { }
