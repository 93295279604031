import { Component, Input, OnInit } from '@angular/core';
import { Court } from '../reservations/reservations-tab/court';
import { Reservation } from '../reservations/reservations-tab/reservation';
import { TimetableApiService } from '../core/api/timetable/timetable-api.service';
import { ReservationDTO } from '../core/api/reservation/reservation.dto';
import { getLocaleDateTimeFormat, Time } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationCreatorComponent } from '../reservations/reservations-tab/reservation-creator/reservation-creator.component';
import { ReservationService } from '../reservations/reservation.service';
import { DayOfWeekService } from '../core/day-of-week.service';
import * as moment from 'moment';
import { UserService } from '../core/user.service';
// tslint:disable-next-line:max-line-length
import { LongTermReservationModificationApi } from '../core/api/long-term-reservation-modification/long-term-reservation-modification-api.service';
import { LongTermReservationModificationDTO } from '../core/api/long-term-reservation-modification/long-term-reservation-modification.dto';
import { ModifyLongTermComponent } from './modify-long-term/modify-long-term.component';
import { ConfirmationService } from '../confirmation-popup/confirmation.service';
import { CourtApi } from '../core/api/court/court-api.service';
import { IntervalService } from './interval.service';
import { ReservationApi } from '../core/api/reservation/reservation-api.service';
import { LoginService } from '../core/api/login/login.service';
import { Roles } from '../core/api/login/roles.enum';
import { Config } from 'src/assets/config';

@Component({
  selector: 'tco-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent implements OnInit {
  barEnabled = Config.barExists;
  _date: Date;
  @Input() set date(value: Date) {
    this._date = value;

    let now = new Date();
    this.isPast = (now.getUTCFullYear() >= value.getUTCFullYear())
      && (now.getUTCMonth() >= value.getUTCMonth())
      && (now.getUTCDate() > value.getUTCDate());

    this.isToday = (now.getUTCFullYear() === value.getUTCFullYear())
      && (now.getUTCMonth() === value.getUTCMonth())
      && (now.getUTCDate() === value.getUTCDate());

    if (this.courts) {
      this.loadForDay(this._date);
    }
  }

  courts: Court[];
  intervals = this.intervalService.intervals;
  isPast = false;
  isToday = true;

  public moment = moment;
  reservations: Reservation[];
  reservationsCourts: { reservations: Reservation[], court: Court }[];
  showLoading: boolean;

  public isHistory = false;
  public isSeasonEnded = false;
  private today: Date;

  adminMode = false;
  barMode = false;

  intervalDeadlockPrevenceEnabled = true;

  constructor(private login: LoginService, public userService: UserService, private timetableApi: TimetableApiService,
    private modalService: NgbModal,
    private reservationService: ReservationService, private dayOfWeekService: DayOfWeekService,
    private longTermModificationApi: LongTermReservationModificationApi, private confirmation: ConfirmationService,
    private courtsApi: CourtApi, private intervalService: IntervalService, private reservationApi: ReservationApi) {
    this.reservationService.reservationChanged.subscribe(() => {
      if (this._date && this.courts) {
        this.loadForDay(this._date);
      }
    });

    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);
  }

  // handle courts
  async ngOnInit() {
    this.adminMode = this.login.role === Roles.SuperAdmin;
    this.barMode = this.login.role >= Roles.Admin;
    this.courts = await this.courtsApi.getCourtsForCurrentSeason();
    if (this._date) {
      this.loadForDay(this._date);
    }
  }

  private async loadForDay(date: Date) {
    this.showLoading = true;

    this.isHistory = date < this.today;


    if (this.adminMode) {
      this.isSeasonEnded = false;
    } else if (date < Config.seasonEndDate && date > Config.seasonStartDate) {
      this.isSeasonEnded = false;
    } else {
      this.isSeasonEnded = true;
    }

    const dtos = await this.timetableApi.getTimetable(date);

    this.reservations = new Array<Reservation>();
    this.reservationsCourts = new Array<{ reservations: Reservation[], court: Court }>();
    let courtsAwaited = await this.courts;
    courtsAwaited.forEach(court => {
      let reservationCourt = { reservations: new Array<Reservation>(), court };

      for (const interval of this.intervals) {
        const emptyRes = new Reservation();
        emptyRes.court = court;
        emptyRes.interval = interval;
        emptyRes.type = 99;
        this.reservations.push(emptyRes);
        reservationCourt.reservations.push(emptyRes);
      }

      this.reservationsCourts.push(reservationCourt);
    });


    dtos.forEach(dto => {
      var reservationCourts = this.reservationsCourts.find(c => c.court.id === dto.courtId);
      this.processReservationDTO(dto, reservationCourts.reservations);
    });

    this.reservations.forEach(x => {
      x.possibleReservations = this.getPossibleReservations(x);
    });

    this.showLoading = false;
  }

  private async processReservationDTO(dto: ReservationDTO, reservations: Reservation[]) {
    let fromTime: Time = { hours: parseInt(dto.fromTime.split(':')[0], 10), minutes: parseInt(dto.fromTime.split(':')[1], 10) };
    let untilTime: Time = { hours: parseInt(dto.untilTime.split(':')[0], 10), minutes: parseInt(dto.untilTime.split(':')[1], 10) };
    let lastReservation: Reservation;

    do {
      let reservation = reservations.find(r => r.interval.from.hours === fromTime.hours && r.interval.from.minutes === fromTime.minutes);
      reservation.type = Number(dto.typeCode);
      reservation.id = dto.id;
      reservation.isFirstInterval = true;
      reservation.isLastInterval = true;
      reservation.price = dto.price;

      reservation.isForCurrentUser = this.userService.user && this.userService.user.id === dto.userId;

      if (lastReservation) {
        if (lastReservation.id === reservation.id) {
          lastReservation.isLastInterval = false;
          reservation.isFirstInterval = false;
        } else {
          lastReservation.isLastInterval = true;
          reservation.isFirstInterval = true;
        }
      }

      lastReservation = reservation;

      if (dto.note) {
        reservation.userFullName = dto.note;
      } else {
        reservation.userFullName = dto.userNameFull;
      }

      fromTime.minutes += 30;

      if (fromTime.minutes === 60) {
        fromTime.hours += 1;
        fromTime.minutes = 0;
      }
    }
    while (fromTime.hours < untilTime.hours || (fromTime.hours === untilTime.hours && fromTime.minutes < untilTime.minutes));
  }

  getReservationsForInterval(interval): Reservation[] {
    let reservations = this.reservations.filter(x => x.interval.from === interval.from);

    return reservations;
  }

  public formatMinutes(minutes: number): string {
    if (minutes === 0) {
      return '00';
    } else {
      return minutes.toString();
    }
  }

  public getPossibleReservations(reservation: Reservation): { res: Reservation, len: number }[] {
    if (reservation.type !== 99) {
      return;
    }

    let currentDuration = 0;
    let possibleReservations = new Array<{ res: Reservation, len: number }>();
    let nextAndPreviousReservations: { previous: Reservation, next: Reservation };

    do {
      currentDuration++;
      reservation.canBeRegisteredByOthersAmount++;

      nextAndPreviousReservations = this.getNextAndPreviousReservations(reservation);

      const next = nextAndPreviousReservations.next;
      const previous = nextAndPreviousReservations.previous;

      // first
      if (!previous && next && next.type !== 99) {
        reservation.isReservable = false;
        return [{ res: reservation, len: currentDuration }];
      } else if (previous && !previous.isReservable && next && next.type !== 99) { // previous reserved, next not empty
        reservation.isReservable = false;
        return [{ res: reservation, len: currentDuration }];
      } else {
        reservation.isReservable = true;
        possibleReservations.push({ res: reservation, len: currentDuration });
      }

      reservation = nextAndPreviousReservations.next;

    } while (reservation && reservation.type === 99);

    return possibleReservations;
  }

  public async openReservationCreateDialog(reservation: Reservation, intervalAmount: number) {
    const modal = this.modalService.open(ReservationCreatorComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true });
    const fromDate = new Date(this._date.setHours(reservation.interval.from.hours, reservation.interval.from.minutes));
    const intervalTimeSpan = intervalAmount * reservation.interval.length.minutes * 60 * 1000;

    let untilDate = new Date(fromDate.getTime() + intervalTimeSpan);

    let untilDateFormatted = moment(untilDate).format('YYYY-MM-DD');
    let fromTime = moment(fromDate).format('HH:mm');
    let untilTime = moment(untilDate).format('HH:mm');

    let price = await this.reservationApi.getReservationPrice(untilDateFormatted, fromTime, untilTime);

    modal.componentInstance.reservation = reservation;
    modal.componentInstance.date = this._date;
    modal.componentInstance.dateFrom = fromDate;
    modal.componentInstance.dateUntil = untilDate;
    modal.componentInstance.barMode = this.barMode;
    modal.componentInstance.price = price;

    modal.result.then(() => {
      this.reservationService.reservationChanged.emit();
    }).catch(() => { });
  }

  public getNextAndPreviousReservations(reservation: Reservation): { previous: Reservation, next: Reservation } {
    const reservationsForCourt = this.reservationsCourts.find(rc => rc.court.id === reservation.court.id).reservations;
    const previous = reservationsForCourt[reservationsForCourt.indexOf(reservation) - 1];
    const next = reservationsForCourt[reservationsForCourt.indexOf(reservation) + 1];

    return { previous, next };
  }

  public getDayName(date: Date) {
    return this.dayOfWeekService.getDayName(date);
  }

  public async cancelReservation(reservation: Reservation) {
    if (!this.barMode) {
      return;
    }

    if (!(reservation.type === 2 || reservation.type === 6 || reservation.type === 7)) {
      return;
    }

    let res = await this.confirmation.presentConfirmationModal(true);
    if (!res) {
      return;
    }

    const dto = new LongTermReservationModificationDTO();
    dto.modifiedById = this.userService.user.id;
    dto.modified = '0';
    dto.note = res.toString();
    dto.reservationId = reservation.id;
    dto.reservationDate = moment(this._date).format('YYYY-MM-DD');
    dto.canceledOnDate = moment(new Date()).format('YYYY-MM-DD');

    await this.longTermModificationApi.modifyLongTermReservation(dto);

    this.reservationService.reservationChanged.emit();
  }

  public onOpenChange(reservation: Reservation) {
    if (this.isSeasonEnded) {
      return;
    }

    if (reservation.type === 99) { // empty spots - open if any options
      if (!reservation.getOptions(this.intervalDeadlockPrevenceEnabled)) {
        return;
      } else {
        this.setReservationSelected(reservation);
      }
    }

    if (reservation.type === 0 && reservation.isForCurrentUser) {
      this.setReservationSelected(reservation);
    }

    if (this.barMode) {
      if (reservation.type === 5 || reservation.type === 6 || reservation.type === 2 || reservation.type === 3 || reservation.type === 7) {
        this.setReservationSelected(reservation);
      }
    }

    if (this.adminMode) {
      if (reservation.type === 1) {
        this.setReservationSelected(reservation);
      }
    }
  }

  private setReservationSelected(reservation: Reservation) {
    if (reservation.id) {
      let isSelected = reservation.isSelected;
      this.reservations.filter(r => r.id === reservation.id).forEach(element => {
        element.isSelected = !isSelected;
      });
    } else {
      reservation.isSelected = !reservation.isSelected; // open otherwise
    }
  }

  // open modal for modify
  public modifyLongTermReservation(reservation: Reservation) {
    let modal = this.modalService.open(ModifyLongTermComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true });
    let allReservationIntervals = this.reservations.filter(r => r.id === reservation.id);
    allReservationIntervals.sort((b, a) => (b.interval.from.hours * 60 + b.interval.from.minutes)
      - (a.interval.from.hours * 60 + a.interval.from.minutes));
    let first = allReservationIntervals[0];
    let last = allReservationIntervals[allReservationIntervals.length - 1];

    modal.componentInstance.reservation = reservation;
    modal.componentInstance.date = this._date;

    let m = moment();
    modal.componentInstance.timeFrom = m.set({ hour: first.interval.from.hours, minute: first.interval.from.minutes }).format('HH:mm');
    modal.componentInstance.timeUntil = m.set({ hour: last.interval.from.hours, minute: last.interval.from.minutes })
      .add({ hours: last.interval.length.hours, minutes: last.interval.length.minutes }).format('HH:mm');

    modal.result.then(() => {
      this.reservationService.reservationChanged.emit();
    }).catch(() => { });
  }

  getReservationPossibleEndTime(from: Time, len: number) {
    return moment().set({ hour: from.hours, minute: from.minutes }).add({ minutes: len * 30 }).format('HH:mm');
  }

  public async cancelBarReservation(reservation: Reservation) {
    if (!this.barMode) {
      return;
    }

    if (reservation.type !== 3) {
      return;
    }

    let res = await this.confirmation.presentConfirmationModal();
    if (!res) {
      return;
    }

    await this.reservationApi.deleteBarUnpaidReservation(reservation.id);
    this.reservationService.reservationChanged.emit();
  }

  public async cancelAdminHourOnceReservation(reservation: Reservation) {
    if (!this.barMode) {
      return;
    }

    if (reservation.type !== 5) {
      return;
    }

    let res = await this.confirmation.presentConfirmationModal();
    if (!res) {
      return;
    }

    await this.reservationApi.deleteAdminHourOnceReservation(reservation.id);
    this.reservationService.reservationChanged.emit();
  }

  public async cancelLongTermOnceReservation(reservation: Reservation) {
    if (!this.barMode) {
      return;
    }

    if (reservation.type !== 7) {
      return;
    }

    let res = await this.confirmation.presentConfirmationModal();
    if (!res) {
      return;
    }

    await this.reservationApi.deleteLongTermOnceReservation(reservation.id);
    this.reservationService.reservationChanged.emit();
  }

  public async setBarReservationPayed(reservation: Reservation) {
    if (!this.barMode) {
      return;
    }

    if (reservation.type !== 3) {
      return;
    }

    let res = await this.confirmation.presentConfirmationModal();
    if (!res) {
      return;
    }

    await this.reservationApi.setReservationAsPaid(reservation.id);
    this.reservationService.reservationChanged.emit();
  }

  async payReservation(reservation: Reservation) {
    let url = await this.reservationApi.getPaymentUrl(reservation.id);
    window.location.href = url;
  }

  async deleteReservation(reservation: Reservation) {
    let res = await this.confirmation.presentConfirmationModal();

    if (!res) {
      return;
    }

    if (reservation.type != 0) {
      await this.reservationApi.deletePaidOnceReservation(reservation.id);
    } else {
      await this.reservationApi.deleteReservationUnpaid(reservation.id);
    }

    this.reservationService.reservationChanged.emit();
  }
}
