// Autor: Vojtěch Randýsek, 2019, VUT FIT

import { ConfiguratorMode } from './configurator-mode';
import { OnInit } from '@angular/core';
import { PagerPipe } from './pipes/pager.pipe';

export abstract class ConfiguratorBase implements OnInit {
    constructor(private pagerPipe: PagerPipe) { }

    mode = ConfiguratorMode.Add;
    Configurator_Mode = ConfiguratorMode;

    // pager
    dataFiltered;
    dataPaged;

    showPager = false;
    itemsTotal;
    currentPage = 1;

    async ngOnInit() {
        await this.refresh();
    }

    abstract async refresh();

    initPager(data: any[]) {
        this.showPager = false;
        this.currentPage = undefined;

        if (data) {
            if (data.length > 10) {
                this.itemsTotal = data.length;
                this.currentPage = 1;
                this.showPager = true;
            }
        }
    }

    paginationChanged(page: number) {
        this.dataPaged = this.pagerPipe.transform(this.dataFiltered, page);
    }

    onRefreshPage() {
        location.reload();
    }
}
