import { Component, OnInit } from '@angular/core';
import { UserApi } from 'src/app/core/api/user/user-api.service';
import { UserTableItem } from './user-table-item';
import { ConfiguratorBase } from 'src/app/core/configurator-base';
import { PagerPipe } from 'src/app/core/pipes/pager.pipe';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserEditorComponent } from './user-editor/user-editor.component';
import { UserService } from 'src/app/core/user.service';
import { Roles } from 'src/app/core/api/login/roles.enum';

@Component({
  selector: 'tco-users-tab',
  templateUrl: './users-tab.component.html',
  styleUrls: ['./users-tab.component.scss']
})
export class UsersTabComponent extends ConfiguratorBase implements OnInit {
  idFilter: string;
  firstNameFilter: string;
  lastNameFilter: string;
  emailFilter: string;
  birthDateFilter: string;
  phoneNumberFilter: string;

  users: Array<UserTableItem>;

  modalData: UserTableItem;

  showPager = true;
  Roles = Roles;

  constructor(private api: UserApi, pager: PagerPipe, private filterPipe: FilterPipe, private modalService: NgbModal,
    public userService: UserService) {
    super(pager);
  }

  async refresh() {
    this.users = new Array<UserTableItem>();
    const users = await this.api.getUsers();
    this.users = users.map(dto => {
      const user = new UserTableItem();
      user.id = dto.id;
      user.pinCode = dto.pinCode;
      user.firstName = dto.firstName;
      user.lastName = dto.lastName;
      user.birthDate = dto.birthDate;
      user.email = dto.email;
      user.phoneNumber = dto.phoneNumber;
      user.reservationEmailNotificationReceiver = dto.reservationEmailNotificationReceiver === '1';
      user.reservationSmsNotificationReceiver = dto.reservationSmsNotificationReceiver === '1';
      return user;
    }).sort((a, b) => Number(b.id) - Number(a.id));

    this.filterData();
  }

  filterData() {
    let usersFiltered = this.filterPipe.transform(this.users, 'id', this.idFilter);
    usersFiltered = this.filterPipe.transform(usersFiltered, 'firstName', this.firstNameFilter);
    usersFiltered = this.filterPipe.transform(usersFiltered, 'lastName', this.lastNameFilter);
    usersFiltered = this.filterPipe.transform(usersFiltered, 'email', this.emailFilter);
    usersFiltered = this.filterPipe.transform(usersFiltered, 'birthDate', this.birthDateFilter, this.filterPipe.convertToLocaleDate);
    usersFiltered = this.filterPipe.transform(usersFiltered, 'phoneNumber', this.phoneNumberFilter);

    this.initPager(usersFiltered);
    this.dataFiltered = usersFiltered;
    this.paginationChanged(this.currentPage);
  }

  public async onRowSelect(rowData: UserTableItem) {
    this.modalData = Object.assign({}, rowData);
    this.modalData.birthDate = new Date(rowData.birthDate);
    let modal = this.modalService.open(UserEditorComponent,
      { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true });
    modal.componentInstance.modalData = this.modalData;
    modal.result.then(() =>
      this.refresh()
    ).catch(() => { });
  }
}
