import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { LoginService } from './login/login.service';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../toast.service';

@Injectable({ providedIn: 'root' })
export class HttpService {
    constructor(private httpClient: HttpClient, private loginService: LoginService, private toastr: ToastService) { }

    callGetPromise<T>(resourceUrl: string): Promise<T> {
        const header = this.constructAuthHeader();
        const result = this.httpClient.get<T>(resourceUrl, { headers: header })
            .pipe(
                catchError((e: any) => this.toastOnError(e, 'Read'))
            )
            .toPromise<any>();
        return result;
    }

    callPostPromise<TRequest>(resourceUrl: string, body: TRequest): Promise<any> {
        const header = this.constructAuthHeader();
        const result = this.httpClient.post<TRequest>(resourceUrl, body, { headers: header })
            .pipe(
                catchError((e: any) => this.toastOnError(e, 'Save'))
            )
            .toPromise<any>();
        return result;
    }

    callPutPromise<TRequest>(resourceUrl: string, body: TRequest): Promise<any> {
        const header = this.constructAuthHeader();
        const result = this.httpClient.put<TRequest>(resourceUrl, body, { headers: header })
            .pipe(
                catchError((e: any) => this.toastOnError(e, 'Update'))
            )
            .toPromise<any>();
        return result;
    }

    callDeletePromise<TRequest>(resourceUrl: string, body: TRequest): Promise<any> {
        const header = this.constructAuthHeader();
        const result = this.httpClient.request<TRequest>('DELETE', resourceUrl, { body: body, headers: header })
            .pipe(
                catchError((e: any) => this.toastOnError(e, 'Delete'))
            ).toPromise();
        return result;
    }

    private constructAuthHeader(): HttpHeaders {
        // along with every request - send also token value within HTTP header
        const token = this.loginService.getAuthToken();
        if (token) {
            let headers = new HttpHeaders();
            headers = headers.set('Authorization', 'Bearer ' + token);

            return headers;
        }
    }

    private toastOnError(error: any, operation: string): Observable<any> {
        if (error.status === 401) {
            this.loginService.logOut();
        } else if (error.status === 422) {
            this.toastr.show(error.error.message, { classname: 'bg-danger text-light', delay: 15000 });
        } else if (error.status == 0) {
            console.log(error);
        }
        else {
            this.toastr.show(`Nespecifikovaná chyba, ${error.status}`, { classname: 'bg-danger text-light', delay: 15000 });
        }

        return throwError(error);
    }
}
