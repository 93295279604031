import { Component } from '@angular/core';
import { Roles } from '../core/api/login/roles.enum';
import { LoginService } from '../core/api/login/login.service';
import { RegistrationComponent } from '../registration/registration.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../core/toast.service';
import { User } from '../reservations/user';

@Component({
  selector: 'tco-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public Roles = Roles;
  public pinCode: string;
  editMode = false;
  userEditModel: User;

  constructor(private login: LoginService, private modalService: NgbModal,
    public toastService: ToastService) { }

  public async onLogin() {
    try {
      await this.login.logIn(this.pinCode);
      this.pinCode = undefined;
    } catch (error) {
      if (error.status === 401) {
        this.toastService.show(`Neplatný pin`, { classname: 'bg-danger text-light', delay: 10000 });
      } else {
        this.toastService.show(`Nespecifikovaná chyba, ${error.status}`, { classname: 'bg-danger text-light', delay: 15000 });
      }
    }
  }

  public openRegistrationModal() {
    this.modalService.open(RegistrationComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true })
      .result.then((result) => {
      }, (reason) => {
      });
  }
}
