import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    try {
      let groups = value.split('.');
      return { day: Number(groups[0]), month: Number(groups[1]), year: Number(groups[2]) };
    } catch {
      return;
    }
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return null;
    }
    return new Date(date.year, date.month - 1, date.day).toLocaleDateString('cs-CS');
  }
}
