import { Component } from '@angular/core';
import { PagerPipe } from 'src/app/core/pipes/pager.pipe';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';
import { DayOfWeekService } from 'src/app/core/day-of-week.service';
import { ReservationApi } from 'src/app/core/api/reservation/reservation-api.service';
import { ConfirmationService } from 'src/app/confirmation-popup/confirmation.service';
import { LoginService } from 'src/app/core/api/login/login.service';
import { Roles } from 'src/app/core/api/login/roles.enum';
import { ConfiguratorBase } from '../core/configurator-base';
import { SubstitutesTableItem } from './substitutes-table-item';
import { SubstituteReservationApi } from '../core/api/substitute-reservations/subsitute-reservation-api.service';
import { ReservationService } from '../reservations/reservation.service';
import { Config } from 'src/assets/config';

@Component({
  selector: 'tco-substitutes',
  templateUrl: './substitutes.component.html',
  styleUrls: ['./substitutes.component.scss']
})
export class SubstitutesComponent extends ConfiguratorBase {
  Roles = Roles;

  substitutes: SubstitutesTableItem[] = [];
  selectedTableRow: SubstitutesTableItem;

  noteFilter: string;
  dateFilter: string;
  fromFilter: string;
  untilFilter: string;
  courtFilter: string;
  createdByFilter: string;

  showPager = true;

  constructor(pager: PagerPipe, private filterPipe: FilterPipe, private api: SubstituteReservationApi,
    private dayOfWeekService: DayOfWeekService, private reservationService: ReservationService,
    private confirm: ConfirmationService, public login: LoginService, private reservationsApi: ReservationApi) {
    super(pager);
  }

  async refresh() {
    const reservationDTOs = await this.api.getSubstituteReservations();

    if (!reservationDTOs) {
      return;
    }

    this.substitutes = reservationDTOs.map<SubstitutesTableItem>((dto) => {
      const ti = new SubstitutesTableItem();
      ti.id = dto.id;
      ti.date = dto.date;
      ti.court = (Number.parseInt(dto.courtId) - Config.courtOffset).toString(10);
      ti.note = dto.note;
      ti.createdBy = dto.createdBy;

      let idx = dto.fromTime.lastIndexOf(':');
      ti.from = dto.fromTime.substring(0, idx < 0 ? dto.fromTime.length : idx);

      idx = dto.untilTime.lastIndexOf(':');
      ti.until = dto.untilTime.substring(0, idx < 0 ? dto.fromTime.length : idx);

      return ti;
    }).sort((a, b) => {
      let res = 0;
      res = b.date.localeCompare(a.date);

      if (res === 0) {
        res = a.from.localeCompare(b.from);
      }

      if (res === 0) {
        res = a.until.localeCompare(b.until);
      }

      return res;
    });

    this.filterData();
  }

  filterData() {
    let reservationsFiltered = this.filterPipe.transform(this.substitutes, 'date', this.dateFilter, this.filterPipe.convertToLocaleDate);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'note', this.noteFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'from', this.fromFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'until', this.untilFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'court', this.courtFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'createdBy', this.createdByFilter);

    this.initPager(reservationsFiltered);
    this.dataFiltered = reservationsFiltered;
    this.paginationChanged(this.currentPage);
  }

  async onRowDelete(reservation: SubstitutesTableItem) {
    let res = await this.confirm.presentConfirmationModal();
    if (!res) {
      return;
    }

    await this.reservationsApi.deleteSubstituteReservation(reservation.id);
    this.reservationService.reservationChanged.emit();
    await this.refresh();
  }
}
