import { Component, OnInit } from '@angular/core';
import { config } from 'process';
import { Config } from 'src/assets/config';

@Component({
  selector: 'tco-reservations-tab',
  templateUrl: './reservations-tab.component.html',
  styleUrls: ['./reservations-tab.component.scss']
})
export class ReservationsTabComponent implements OnInit {
  public isMobile = false;
  public seasonEndDate = { year: Config.seasonEndDate.getFullYear(), month: Config.seasonEndDate.getMonth() + 1, day: Config.seasonEndDate.getDate() }
  public seasonStartDate = { year: Config.seasonStartDate.getFullYear(), month: Config.seasonStartDate.getMonth() + 1, day: Config.seasonStartDate.getDate() }
  date = new Date();

  constructor() { }

  async ngOnInit() {
    this.isMobile = window.innerWidth <= 991;
    window.onresize = () => this.isMobile = window.innerWidth <= 991;
  }

  incrementDate() {
    this.date = new Date(this.date.valueOf() + 24 * 60 * 60 * 1000);
  }

  decrementDate() {
    this.date = new Date(this.date.valueOf() - 24 * 60 * 60 * 1000);
  }
}
