import { Injectable } from '@angular/core';
import { ApiProviderBase } from '../api-provider-base';
import { HttpService } from '../http-service';
import { LongTermReservationModificationDTO } from './long-term-reservation-modification.dto';

@Injectable({ providedIn: 'root' })
export class LongTermReservationModificationApi extends ApiProviderBase {
    constructor(httpService: HttpService) {
        super(httpService);
    }

    public getLongTermReservationModifications(): Promise<LongTermReservationModificationDTO[]> {
        const url = `long-term-reservation-modification.php`;
        return this.callGetPromise<LongTermReservationModificationDTO[]>(url);
    }

    public modifyLongTermReservation(data: LongTermReservationModificationDTO): Promise<boolean> {
        return this.callPostPromise(`long-term-reservation-modification.php`, data);
    }

    public deleteLongTermModification(id: string): Promise<boolean> {
        return this.callDeletePromise(`long-term-reservation-modification.php`, { reservationModificationId: id });
    }
}
