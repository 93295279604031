import { Injectable } from '@angular/core';
import { ApiProviderBase } from '../api-provider-base';
import { HttpService } from '../http-service';
import { ReservationDTO } from '../reservation/reservation.dto';

@Injectable({ providedIn: 'root' })
export class TimetableApiService extends ApiProviderBase {
    constructor(httpService: HttpService) {
        super(httpService);
    }

    public getTimetable(date: Date): Promise<ReservationDTO[]> {
        let url = `timetable.php`;

        let dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
            .toISOString()
            .split('T')[0];

        url += `?date=${dateString}`;


        url += `&day=${this.getDayNumberOneToSeven(date)}`;

        return this.callGetPromise<ReservationDTO[]>(url);
    }

    private getDayNumberOneToSeven(date: Date) {
        let day = date.getDay();
        if (day === 0) {
            return 7;
        } else {
            return day;
        }
    }
}
