import { Roles } from '../core/api/login/roles.enum';

export class User {
    firstName: string;
    lastName: string;
    birthDate: any;
    role: Roles;
    email: string;
    phoneNumber: string;
    reservationEmailNotificationReceiver: boolean;
    reservationSmsNotificationReceiver: boolean;
    id: string;
}
