import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserApi } from 'src/app/core/api/user/user-api.service';
import { ConfiguratorMode } from 'src/app/core/configurator-mode';
import { UserTableItem } from '../user-table-item';
import { UserDTO } from 'src/app/core/api/account/user-dto';
import * as moment from 'moment';
import { ConfirmationService } from 'src/app/confirmation-popup/confirmation.service';

@Component({
  selector: 'tco-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.scss']
})
export class UserEditorComponent implements OnInit {
  @Input() modalData: UserTableItem;

  constructor(private api: UserApi, private modal: NgbActiveModal, private confirmation: ConfirmationService) { }

  ngOnInit() {
  }

  public async onDeleteClicked() {
    let res = await this.confirmation.presentConfirmationModal();
    if (!res) { return; }

    await this.api.deleteUser(this.modalData.id);
    this.dismissModal();
  }

  public async onSaveClicked() {
    let dto = new UserDTO();
    dto.id = this.modalData.id;
    dto.firstName = this.modalData.firstName;
    dto.lastName = this.modalData.lastName;
    dto.birthDate = moment(this.modalData.birthDate).format('YYYY-MM-DD');
    dto.email = this.modalData.email;
    dto.phoneNumber = this.modalData.phoneNumber;
    dto.reservationEmailNotificationReceiver = this.modalData.reservationEmailNotificationReceiver ? '1' : '0';
    dto.reservationSmsNotificationReceiver = this.modalData.reservationSmsNotificationReceiver ? '1' : '0';
    await this.api.modifyUser(dto);
    this.dismissModal();
  }

  dismissModal() {
    this.modal.close();
  }
}
