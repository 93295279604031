export class LongTermReservationDTO {
    id: string;
    courtId: string;
    dayOfWeek: number;
    fromTime: string;
    untilTime: string;
    note: string;
    createdBy: string;
    isAdminReservation = false;
}
