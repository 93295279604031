import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './core/user.service';
import { Config } from 'src/assets/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isFullscreen = false;

  siteName = Config.siteName;
  siteSubtitle = Config.homeSubtitle;

  public config = Config;

  constructor(private router: Router, private userService: UserService) {
    this.isFullscreen = localStorage.getItem('fullscreen') === '1';
  }

  async ngOnInit() {
    await this.userService.refreshUser();
  }

  reloadHomePage() {
    if (this.router.url === '') {
      location.reload();
    } else {
      this.router.navigate(['']);
    }
  }

  setFullscreen(value: boolean) {
    this.isFullscreen = value;

    if (!value) {
      localStorage.removeItem('fullscreen');
    } else {
      localStorage.setItem('fullscreen', '1');
    }
  }
}
