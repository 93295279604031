import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DayOfWeekService {
  // for week starting with 1 = Pondělí
  private days = ['Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle'];

  public getDayName(date: Date) {
    let dayNumber = date.getDay();
    if (dayNumber === 0) {
      dayNumber = 7;
    }
    return this.days[dayNumber - 1];
  }

  public getDayNameByNumber(dayNumber: number): string {
    return this.days[dayNumber - 1];
  }

  public getDayNumber(name: string): number {
    return this.days.indexOf(name);
  }
}
