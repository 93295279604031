import { Time } from '@angular/common';

export class Interval {
    from: Time;
    length: Time = { hours: 0, minutes: 30 };

    get isPast(): boolean {
        let now = new Date();
        if (this.from.hours < now.getHours()) {
            return true;
        }

        if (now.getHours() === this.from.hours && this.from.minutes <= now.getMinutes()) {
            return true;
        }

        return false;
    }
}
