export class BarReservationTableItem {
    id: string;
    date: string;
    from: string;
    until: string;
    court: string;
    note: string;
    isPaid: boolean;
    price: number;
    createdBy: string;
}
