import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopupComponent } from './confirmation-popup.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
    constructor(private modalService: NgbModal) { }

    async presentConfirmationModal(showInput = false): Promise<boolean> {
        let modal = this.modalService.open(ConfirmationPopupComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', centered: true });
        modal.componentInstance.showInput = showInput;

        try {
            let res = await modal.result;
            if (showInput) {
                return res;
            }
            return true;
        } catch {
            return false;
        }
    }
}
