import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
    name: 'role',
})
@Injectable({ providedIn: 'root' })
export class RolePipe implements PipeTransform {
    transform(value: any): any {
        if (!value) {
            return;
        }

        if (value === '0') {
            return 'Nepřihlášen';
        } else if (value === '1') {
            return 'Uživatel';
        } else if (value === '2') {
            return 'Admin';
        } else if (value === '3') {
            return 'SuperAdmin';
        } else {
            return value;
        }
    }
}
