import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Config } from 'src/assets/config';

@Component({
  selector: 'tco-post-registration',
  templateUrl: './post-registration.component.html',
  styleUrls: ['./post-registration.component.scss']
})
export class PostRegistrationComponent {
  @Input() pinCode: string;
  @Input() email: string;
  @Input() mailSentSuccess: boolean;

  barExists = Config.barExists;
  constructor(private modal: NgbActiveModal) { }

  onConfirm() {
    this.modal.close();
  }
}
