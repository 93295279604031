// Autor: Vojtěch Randýsek, 2019, VUT FIT

import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
    name: 'pager',
})
@Injectable({providedIn: 'root'})
export class PagerPipe implements PipeTransform {
    transform(items: any[], page: number): any[] {
        if (!items) {
            return [];
        }
        if (!page) {
            return items;
        }
        const fromItem = (page - 1) * 10;
        const itemsOnPage = items.slice(fromItem, fromItem + 10);
        return itemsOnPage;
    }
}
