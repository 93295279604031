import { Injectable } from '@angular/core';
import { ApiProviderBase } from '../api-provider-base';
import { HttpService } from '../http-service';
import { LongTermReservationDTO } from './long-term-reservation.dto';

@Injectable({ providedIn: 'root' })
export class LongTermReservationApi extends ApiProviderBase {
    constructor(httpService: HttpService) {
        super(httpService);
    }

    public getLongTermReservations(): Promise<LongTermReservationDTO[]> {
        const url = `long-term-reservation.php`;
        return this.callGetPromise<LongTermReservationDTO[]>(url);
    }

    public createLongTermReservation(data: LongTermReservationDTO): Promise<boolean> {
        return this.callPostPromise(`long-term-reservation.php`, data);
    }
}
