import { Roles } from '../login/roles.enum';

export class UserDTO {
    id: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    role: Roles;
    email: string;
    reservationEmailNotificationReceiver: string;
    reservationSmsNotificationReceiver: string;
    pinCode: string;
    phoneNumber: string;
}
