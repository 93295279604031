import { Component } from '@angular/core';
import { ConfiguratorBase } from 'src/app/core/configurator-base';
import { ModifiedLongTermReservationTableItem } from './modified-long-term-reservation-table-time';
import { PagerPipe } from 'src/app/core/pipes/pager.pipe';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';
// tslint:disable-next-line: max-line-length
import { LongTermReservationModificationApi } from 'src/app/core/api/long-term-reservation-modification/long-term-reservation-modification-api.service';
import { DayOfWeekService } from 'src/app/core/day-of-week.service';
import { ConfirmationService } from 'src/app/confirmation-popup/confirmation.service';
import { ReservationService } from '../../reservation.service';
import { Roles } from 'src/app/core/api/login/roles.enum';
import { LoginService } from 'src/app/core/api/login/login.service';

@Component({
  selector: 'tco-modifed-long-term-reservations',
  templateUrl: './modifed-long-term-reservations.component.html',
  styleUrls: ['./modifed-long-term-reservations.component.scss']
})
export class ModifedLongTermReservationsComponent extends ConfiguratorBase {
  reservations: ModifiedLongTermReservationTableItem[] = [];

  dateFilter: string;
  noteFilter: string;
  modifiedFilter: string;
  modifiedFromTimeFilter: string;
  modifiedUntilTimeFilter: string;
  modifiedByFilter: string;
  canceledDateFilter: string;
  originalFromTimeFilter: string;
  originalUntilTimeFilter: string;
  originalDayFilter: string;
  courtCodeFilter: string;
  originalNoteFilter: string;

  showPager = true;

  constructor(pager: PagerPipe, private filterPipe: FilterPipe, private api: LongTermReservationModificationApi,
    private confirmation: ConfirmationService, private dayOfWeekService: DayOfWeekService, private login: LoginService,
    private reservationService: ReservationService) {
    super(pager);
  }

  async refresh() {
    const reservationDTOs = await this.api.getLongTermReservationModifications();
    if (!reservationDTOs) {
      return;
    }

    this.reservations = reservationDTOs.map<ModifiedLongTermReservationTableItem>((dto) => {
      const ti = new ModifiedLongTermReservationTableItem();
      ti.id = dto.id;
      ti.reservationId = dto.reservationId;
      ti.reservationDate = dto.reservationDate;
      ti.note = dto.note;
      ti.modified = dto.modified === '1';
      ti.modifiedById = dto.modifiedById;
      ti.modifiedByName = dto.modifiedByName;
      ti.canceledOnDate = dto.canceledOnDate;
      ti.originalDay = this.dayOfWeekService.getDayNameByNumber(dto.originalDay);
      ti.courtCode = dto.courtCode;
      ti.originalNote = dto.originalNote;

      let idx = dto.originalFromTime.lastIndexOf(':');
      ti.originalFromTime = dto.originalFromTime.substring(0, idx < 0 ? dto.originalFromTime.length : idx);

      idx = dto.originalUntilTime.lastIndexOf(':');
      ti.originalUntilTime = dto.originalUntilTime.substring(0, idx < 0 ? dto.originalUntilTime.length : idx);

      if (ti.modified) {
        idx = dto.modifiedFromTime.lastIndexOf(':');
        ti.modifiedFromTime = dto.modifiedFromTime.substring(0, idx < 0 ? dto.modifiedFromTime.length : idx);

        idx = dto.modifiedUntilTime.lastIndexOf(':');
        ti.modifiedUntilTime = dto.modifiedUntilTime.substring(0, idx < 0 ? dto.modifiedUntilTime.length : idx);
      }

      return ti;
    }).sort(
      (a, b) => {
        let result = b.reservationDate.localeCompare(a.reservationDate);
        if (result === 0) {
          result = b.originalFromTime.localeCompare(a.originalFromTime);
        }
        return result;
      });

    this.filterData();
  }

  filterData() {
    let reservationsFiltered = this.filterPipe.transform(this.reservations, 'reservationDate', this.dateFilter,
      this.filterPipe.convertToLocaleDate);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'note', this.noteFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'modified', this.modifiedFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'modifiedFromTime', this.modifiedFromTimeFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'modifiedUntilTime', this.modifiedUntilTimeFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'modifiedByName', this.modifiedByFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'canceledOnDate', this.canceledDateFilter,
      this.filterPipe.convertToLocaleDate);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'originalFromTime', this.originalFromTimeFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'originalUntilTime', this.originalUntilTimeFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'originalDay', this.originalDayFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'courtCode', this.courtCodeFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'originalNote', this.originalNoteFilter);

    this.initPager(reservationsFiltered);
    this.dataFiltered = reservationsFiltered;
    this.paginationChanged(this.currentPage);
  }

  async onRowDelete(reservationModification: ModifiedLongTermReservationTableItem) {
    let res = await this.confirmation.presentConfirmationModal();
    if (!res) { return; }

    await this.api.deleteLongTermModification(reservationModification.id);
    this.reservationService.reservationChanged.emit();

    await this.refresh();
  }

  canBeDeleted(reservation: ModifiedLongTermReservationTableItem): boolean {
    if (this.login.role === Roles.SuperAdmin) {
      return true;
    }

    let now = new Date();
    let newDate = new Date(reservation.reservationDate);

    if (reservation.modified) {
      newDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(),
        Number(reservation.modifiedFromTime.split(':')[0]), Number(reservation.modifiedFromTime.split(':')[1]));
      return now.valueOf() < newDate.valueOf();
    } else {
      newDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(),
        Number(reservation.originalFromTime.split(':')[0]), Number(reservation.originalFromTime.split(':')[1]));
      return now.valueOf() < newDate.valueOf();
    }
  }
}
