import { Injectable } from '@angular/core';
import { ReservationDTO } from './reservation.dto';
import { ApiProviderBase } from '../api-provider-base';
import { HttpService } from '../http-service';

@Injectable({ providedIn: 'root' })
export class ReservationApi extends ApiProviderBase {
    constructor(httpService: HttpService) {
        super(httpService);
    }

    public getReservations(userId?: string, day?: Date): Promise<ReservationDTO[]> {
        let url = `reservation.php`;

        if (userId) {
            url += `?userId=${userId}`;
        }

        if (day) {
            url += `?day=${day.toISOString()}`;
        }

        return this.callGetPromise<ReservationDTO[]>(url);
    }

    public createReservation(data: ReservationDTO): Promise<string> {
        return this.callPostPromise(`reservation.php`, data);
    }

    public setReservationAsPaid(reservationId: string): Promise<boolean> {
        let data = {
            reservationId: reservationId
        };

        return this.callPutPromise(`reservation.php`, data);
    }

    public deleteBarUnpaidReservation(reservationId: string): Promise<boolean> {
        let data = {
            reservationId: reservationId,
            typeCode: 3
        };

        return this.callDeletePromise(`reservation.php`, data);
    }

    public deleteAdminHourOnceReservation(reservationId: string): Promise<boolean> {
        let data = {
            reservationId: reservationId,
            typeCode: 5
        };

        return this.callDeletePromise(`reservation.php`, data);
    }

    public deleteLongTermOnceReservation(reservationId: string): Promise<boolean> {
        let data = {
            reservationId: reservationId,
            typeCode: 7
        };

        return this.callDeletePromise(`reservation.php`, data);
    }

    public deleteLongTermReservation(reservationId: string): Promise<boolean> {
        let data = {
            reservationId: reservationId,
            typeCode: 2
        };

        return this.callDeletePromise(`reservation.php`, data);
    }

    public deleteAdminLongTermReservation(reservationId: string): Promise<boolean> {
        let data = {
            reservationId: reservationId,
            typeCode: 6
        };

        return this.callDeletePromise(`reservation.php`, data);
    }

    public deleteSubstituteReservation(reservationId: string): Promise<boolean> {
        let data = {
            reservationId: reservationId,
            typeCode: 5
        };

        return this.callDeletePromise(`reservation.php`, data);
    }

    public getPaymentUrl(reservationId: string): Promise<string> {
        return this.callGetPromise<string>(`gpwebpay/get_payment_call.php?reservationId=${reservationId}`);
    }

    public deleteReservationUnpaid(reservationId: string): Promise<void> {
        return this.callDeletePromise(`reservation-unpaid.php`, { reservationId });
    }

    public getReservationPrice(date: string, fromTime: string, untilTime: string): Promise<number> {
        return this.callGetPromise<number>(`reservation-price.php?date=${date}&fromTime=${fromTime}&untilTime=${untilTime}`);
    }

    public deletePaidOnceReservation(reservationId: string): Promise<boolean> {
        let data = {
            reservationId: reservationId,
            typeCode: 1
        };

        return this.callDeletePromise(`reservation.php`, data);
    }
}
