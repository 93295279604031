import { Court } from './court';
import { Interval } from './interval';

export class Reservation {
    id: string;
    court: Court;
    type: number; // 3 = pay on bar, 4 = pay on bar and payed, 5 admin short, 6 admin long
    interval: Interval;
    emptyCells: number;
    possibleReservations: { res: Reservation, len: number }[];
    isReservable: boolean;
    isSelected: boolean;
    userFullName: string;
    canBeRegisteredByOthersAmount = 0;
    isFirstInterval: boolean;
    isLastInterval: boolean;
    isForCurrentUser: boolean;
    price: number;

    isPossibleDeadInterval() {
        return this.possibleReservations && this.possibleReservations.length === 1;
    }

    getOptions(intervalDeadlockPrevenceEnabled = true): { res: Reservation, len: number }[] {
        if (intervalDeadlockPrevenceEnabled === false) {
            return this.possibleReservations;
        }

        if (!this.possibleReservations) {
            return;
        }

        if (this.possibleReservations.length < 2) {
            return;
        }

        let withoutSelf = this.possibleReservations.slice(1, this.possibleReservations.length);
        return withoutSelf;
    }
}
