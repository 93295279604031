import { Pipe, PipeTransform, Injectable } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'reservationDuration',
})
@Injectable({ providedIn: 'root' })
export class ReservationDurationPipe implements PipeTransform {
    public moment = moment;

    transform(value: number): any {
        if (!value) {
            return;
        }

        const asMoment = moment.utc(value * 30 * 60 * 1000);
        return `${asMoment.format('H')}h ${asMoment.format('mm')}m`;
    }
}
