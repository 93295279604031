import { Component, OnInit } from '@angular/core';
import { Roles } from '../core/api/login/roles.enum';
import { LoginService } from '../core/api/login/login.service';
import { SelectedTab } from './selected-tab';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Config } from 'src/assets/config';

@Component({
  selector: 'tco-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit {
  public barEnabled = Config.barExists;

  public Roles = Roles;
  public pinCode: string;

  private _selectedTab: string;
  public set selectedTab(value: string) {
    this.setTabQueryParam(value);
    this._selectedTab = value;
  }

  public get selectedTab(): string {
    return this._selectedTab;
  }

  public SelectedTab = SelectedTab;

  constructor(public login: LoginService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    const firstParam: string = this.route.snapshot.queryParamMap.get('tab');
    if (firstParam) {
      this.selectedTab = firstParam;
    }
  }

  private setTabQueryParam(value: string) {
    const queryParams: Params = { tab: value };

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams
      });
  }
}
