import { Component } from '@angular/core';
import { ConfiguratorBase } from 'src/app/core/configurator-base';
import { PagerPipe } from 'src/app/core/pipes/pager.pipe';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';
import { ReservationService } from '../reservation.service';
import { AllReservationsApi } from 'src/app/core/api/all-reservations/all-reservations-api.service';
import { BarReservationTableItem } from './bar-reservation-table-item';
import { BarReservationsApi } from 'src/app/core/api/bar-reservations/bar-reservations-api.service';
import { Config } from 'src/assets/config';

@Component({
  selector: 'tco-bar-reservations-list',
  templateUrl: './bar-reservations-list.component.html',
  styleUrls: ['./bar-reservations-list.component.scss']
})
export class BarReservationsListComponent extends ConfiguratorBase {
  reservations: BarReservationTableItem[];

  noteFilter: string;
  dateFilter: string;
  fromFilter: string;
  untilFilter: string;
  courtFilter: string;
  paidFilter: string;
  priceFilter: string;
  createdByFilter: string;

  showPager = true;

  constructor(pager: PagerPipe, private filterPipe: FilterPipe,
    private api: BarReservationsApi, reservationService: ReservationService) {
    super(pager);
    reservationService.reservationChanged.subscribe(() => this.refresh());
  }

  async refresh() {
    const reservationDTOs = await this.api.getBarReservations();
    if (!reservationDTOs) {
      this.reservations = undefined;
      return;
    }

    this.reservations = reservationDTOs.map<BarReservationTableItem>((dto) => {
      const ti = new BarReservationTableItem();
      ti.id = dto.id;
      ti.date = dto.date;
      ti.court = (Number.parseInt(dto.courtId) - Config.courtOffset).toString(10);
      ti.note = dto.note;
      ti.isPaid = Number(dto.typeCode) === 4 ? true : false;
      ti.price = dto.price;
      ti.createdBy = dto.userNameFull;

      let idx = dto.fromTime.lastIndexOf(':');
      ti.from = dto.fromTime.substring(0, idx < 0 ? dto.fromTime.length : idx);

      idx = dto.untilTime.lastIndexOf(':');
      ti.until = dto.untilTime.substring(0, idx < 0 ? dto.fromTime.length : idx);

      return ti;
    }).sort((a, b) => {
      let res = b.date.localeCompare(a.date);
      if (res === 0) {
        res = b.from.localeCompare(a.from);
      }
      if (res === 0) {
        res = b.until.localeCompare(a.until);
      }
      return res;
    });

    this.filterData();
  }

  filterData() {
    let reservationsFiltered = this.filterPipe.transform(this.reservations, 'date', this.dateFilter, this.filterPipe.convertToLocaleDate);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'note', this.noteFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'from', this.fromFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'until', this.untilFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'court', this.courtFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'isPaid', this.paidFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'price', this.priceFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'createdBy', this.createdByFilter);

    this.initPager(reservationsFiltered);
    this.dataFiltered = reservationsFiltered;
    this.paginationChanged(this.currentPage);
  }
}
