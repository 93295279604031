import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModifedLongTermReservationsComponent } from './modifed-long-term-reservations.component';

@NgModule({
    declarations: [
        ModifedLongTermReservationsComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        PipesModule,
        NgbModule
    ],
    providers: [

    ],
    exports: [
        ModifedLongTermReservationsComponent
    ]
})
export class ModifiedLongTermReservationModule { }
