import { Injectable } from '@angular/core';
import { ApiProviderBase } from '../api-provider-base';
import { HttpService } from '../http-service';
import { ReservationDTO } from '../reservation/reservation.dto';

@Injectable({ providedIn: 'root' })
export class BarReservationsApi extends ApiProviderBase {
    constructor(httpService: HttpService) {
        super(httpService);
    }

    public getBarReservations(): Promise<ReservationDTO[]> {
        const url = `bar-reservations.php`;

        return this.callGetPromise<ReservationDTO[]>(url);
    }
}
