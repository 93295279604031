// Autor: Vojtěch Randýsek, 2019, VUT FIT

import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { isNumber } from 'util';

@Pipe({
    name: 'filter',
})
@Injectable({ providedIn: 'root' })
export class FilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: any, prepareFunction?: Function): any[] {
        if (!items) {
            return [];
        }
        if (!field || !value) {
            return items;
        }

        value = value.toString();

        return items.filter(singleItem => {
            if (singleItem[field] === null || singleItem[field] === undefined) {
                return false;
            }

            let fieldValue: string = singleItem[field].toString();
            if (prepareFunction) {
                fieldValue = prepareFunction(fieldValue);
            }

            return fieldValue.includes(value);
        });
    }

    convertToLocaleDate(dateString: string): string {
        if (!dateString) {
            return undefined;
        }

        let date = new Date(dateString);
        return date.toLocaleDateString('cs');
    }
}
