import { Interval } from '../reservations/reservations-tab/interval';
import { Config } from 'src/assets/config';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class IntervalService {
    public intervals: Interval[];

    constructor() {
        let intervals = new Array<Interval>();
        let startingTimeMinutes = Config.startingHour * 60 + Config.startingMinute;
        let endTime = Config.endHour * 60 + Config.endMinute;

        while (startingTimeMinutes < endTime) {
            let interval = new Interval();
            interval.from = { hours: Math.floor(startingTimeMinutes / 60), minutes: startingTimeMinutes % 60 };
            interval.length = { hours: 0, minutes: Config.intervalLengthMins };
            intervals.push(interval);
            startingTimeMinutes += Config.intervalLengthMins;
        }

        this.intervals = intervals;
    }
}
