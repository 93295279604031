import { Component, Input } from '@angular/core';
import { Reservation } from '../reservation';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/core/user.service';

import * as moment from 'moment';
import { ReservationApi } from 'src/app/core/api/reservation/reservation-api.service';
import { ReservationDTO } from 'src/app/core/api/reservation/reservation.dto';
import { DayOfWeekService } from 'src/app/core/day-of-week.service';
import { LongTermReservationDTO } from 'src/app/core/api/long-term-reservations/long-term-reservation.dto';
import { LongTermReservationApi } from 'src/app/core/api/long-term-reservations/long-term-reservation-api.service';
import { Roles } from 'src/app/core/api/login/roles.enum';

@Component({
  selector: 'app-reservation-creator',
  templateUrl: './reservation-creator.component.html',
  styleUrls: ['./reservation-creator.component.scss']
})
export class ReservationCreatorComponent {
  @Input() reservation: Reservation;
  @Input() date: Date;
  @Input() dateFrom: Date;
  @Input() dateUntil: Date;
  @Input() barMode: boolean;
  @Input() price: number;

  note: string;
  Roles = Roles;
  public moment = moment;
  toggleModel = 'once-system';

  constructor(private activeModal: NgbActiveModal, public userService: UserService,
    // tslint:disable-next-line:align
    private api: ReservationApi, private dayOfWeekService: DayOfWeekService, private longTermApi: LongTermReservationApi) { }

  async confirm() {
    if (this.toggleModel === 'admin-long-term') {
      // long term admin reservation
      const dto = new LongTermReservationDTO();
      dto.createdBy = this.userService.user.id;
      dto.dayOfWeek = moment(this.date).isoWeekday();
      dto.courtId = this.reservation.court.id;
      dto.fromTime = moment(this.dateFrom).format('HH:mm');
      dto.untilTime = moment(this.dateUntil).format('HH:mm');
      dto.isAdminReservation = true;
      dto.note = this.note;
      await this.longTermApi.createLongTermReservation(dto);
    } else if (this.toggleModel === 'admin-once') {
      // once admin reservation
      const dto = new ReservationDTO();
      dto.userId = this.userService.user.id;
      dto.date = moment(this.date).format('YYYY-MM-DD');
      dto.typeCode = 5;
      dto.courtId = this.reservation.court.id;
      dto.fromTime = moment(this.dateFrom).format('HH:mm');
      dto.untilTime = moment(this.dateUntil).format('HH:mm');
      dto.note = this.note;
      await this.api.createReservation(dto);
    } else if (this.toggleModel === 'admin-long-term-once') {
      // long term that started later
      const dto = new ReservationDTO();
      dto.userId = this.userService.user.id;
      dto.date = moment(this.date).format('YYYY-MM-DD');
      dto.typeCode = 7;
      dto.courtId = this.reservation.court.id;
      dto.fromTime = moment(this.dateFrom).format('HH:mm');
      dto.untilTime = moment(this.dateUntil).format('HH:mm');
      dto.note = this.note;
      await this.api.createReservation(dto);
    } else if (this.toggleModel === 'long-term') {
      // regular long term
      const dto = new LongTermReservationDTO();
      dto.createdBy = this.userService.user.id;
      dto.dayOfWeek = moment(this.date).isoWeekday();
      dto.courtId = this.reservation.court.id;
      dto.fromTime = moment(this.dateFrom).format('HH:mm');
      dto.untilTime = moment(this.dateUntil).format('HH:mm');
      dto.note = this.note;
      await this.longTermApi.createLongTermReservation(dto);
    } else if (this.toggleModel === 'once-bar') {
      // no payment reservation
      const dto = new ReservationDTO();
      dto.userId = this.userService.user.id;
      dto.date = moment(this.date).format('YYYY-MM-DD');
      dto.typeCode = 3;
      dto.note = this.note;
      dto.courtId = this.reservation.court.id;
      dto.fromTime = moment(this.dateFrom).format('HH:mm');
      dto.untilTime = moment(this.dateUntil).format('HH:mm');
      await this.api.createReservation(dto);
    } else {
      // regular once reservation
      const dto = new ReservationDTO();
      dto.userId = this.userService.user.id;
      dto.date = moment(this.date).format('YYYY-MM-DD');
      dto.typeCode = 0; // 0 means not payed yet - should be set to 1 in payment handler
      dto.courtId = this.reservation.court.id;
      dto.fromTime = moment(this.dateFrom).format('HH:mm');
      dto.untilTime = moment(this.dateUntil).format('HH:mm');

      if (this.userService.user.role >= Roles.Admin && this.note) {
        dto.note = this.note;
      }

      let id = await this.api.createReservation(dto);
      let url = await this.api.getPaymentUrl(id);

      document.location.href = url;
    }

    this.activeModal.close(); // resolve
  }

  cancel() {
    this.activeModal.dismiss(); // reject
  }

  public getDayName(date: Date) {
    return this.dayOfWeekService.getDayName(date);
  }
}
