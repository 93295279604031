import { Component, OnInit } from '@angular/core';
import { KPIApi } from '../core/api/kpi/kpi-api.service';
import { LoginService } from '../core/api/login/login.service';
import { Roles } from '../core/api/login/roles.enum';
import { ReservationService } from '../reservations/reservation.service';
import { Config } from 'src/assets/config';

@Component({
  selector: 'tco-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KpiComponent implements OnInit {
  barEnabled = Config.barExists;

  constructor(private api: KPIApi, public loginService: LoginService, reservationService: ReservationService) {
    reservationService.reservationChanged.subscribe(() => this.ngOnInit());
  }

  // user
  userTotalHours: number;
  userTotalMinutes: number;
  userTotalReservations: number;

  // today stats
  longTermCount: number;
  systemCount: number;
  barCount: number;
  barAmountCash: number;

  Roles = Roles;

  async ngOnInit() {
    await this.loginService.loaded;

    if (this.loginService.role === Roles.Unauthorized) {
      return;
    }

    if (this.loginService.role >= Roles.User) {
      this.api.getUserKPI().then(dto => {
        // https://stackoverflow.com/questions/4228356/integer-division-with-remainder-in-javascript
        // tslint:disable-next-line: no-bitwise
        this.userTotalHours = (dto.totalTimeMinutes / 60 >> 0);
        this.userTotalMinutes = dto.totalTimeMinutes % 60;
        this.userTotalReservations = dto.totalReservations;
      });
    }

    if (this.loginService.role >= Roles.Admin) {
      this.api.getBarKPI().then(dto => {
        this.systemCount = dto.systemCount;
        this.barCount = dto.barCount;
        this.barAmountCash = dto.barAmountCash;
      });
    }

    if (this.loginService.role === Roles.SuperAdmin) {

    }
  }
}
