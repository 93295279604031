import { Roles } from 'src/app/core/api/login/roles.enum';

export class UserTableItem {
    id: string;
    pinCode: string;
    firstName: string;
    lastName: string;
    birthDate: any;
    email: string;
    phoneNumber: string;
    reservationEmailNotificationReceiver: boolean;
    reservationSmsNotificationReceiver: boolean;
}
