export class ModifiedLongTermReservationTableItem {
    id: string;
    reservationId: string;
    reservationDate: string;
    canceledOnDate: string;
    note: string;
    modified: boolean;
    modifiedFromTime: string;
    modifiedUntilTime: string;
    modifiedByName: string;
    modifiedById: string;
    originalFromTime: string;
    originalUntilTime: string;
    originalDay: string;
    originalNote: string;
    courtCode: string;
}
