export class ReservationTableItem {
    id: string;
    note: string;
    date: string;
    from: string;
    until: string;
    court: string;
    typeCode: number;
    price: number;
    deletionTime: string;
}
