import { Injectable } from '@angular/core';
import { ApiProviderBase } from '../api-provider-base';
import { HttpService } from '../http-service';
import { UserDTO } from '../account/user-dto';

@Injectable({ providedIn: 'root' })
export class UserApi extends ApiProviderBase {
    constructor(httpService: HttpService) {
        super(httpService);
    }

    public getUsers(): Promise<UserDTO[]> {
        return this.callGetPromise<UserDTO[]>(`user.php`);
    }

    public modifyUser(data: UserDTO): Promise<boolean> {
        return this.callPostPromise(`user.php`, data);
    }

    public deleteUser(userId: string): Promise<void> {
        return this.callDeletePromise(`user.php?id=${userId}`);
    }
}
