import { Component } from '@angular/core';
import { ConfiguratorBase } from 'src/app/core/configurator-base';
import { ReservationTableItem } from './reservation-table-item';
import { PagerPipe } from 'src/app/core/pipes/pager.pipe';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';
import { ReservationApi } from 'src/app/core/api/reservation/reservation-api.service';
import { ReservationService } from '../reservation.service';
import { ConfirmationService } from 'src/app/confirmation-popup/confirmation.service';
import { LoginService } from 'src/app/core/api/login/login.service';
import { Roles } from 'src/app/core/api/login/roles.enum';
import { Config } from 'src/assets/config';

@Component({
  selector: 'tco-reservations-list',
  templateUrl: './reservations-list.component.html',
  styleUrls: ['./reservations-list.component.scss']
})
export class ReservationsListComponent extends ConfiguratorBase {
  Roles = Roles;
  reservations: ReservationTableItem[];
  selectedTableRow: ReservationTableItem;

  dateFilter: string;
  fromFilter: string;
  untilFilter: string;
  courtFilter: string;
  priceFilter: string;
  noteFilter: string;

  showPager = true;

  constructor(pager: PagerPipe, private filterPipe: FilterPipe,
    // tslint:disable-next-line:align
    private api: ReservationApi, private reservationService: ReservationService,
    private confirmation: ConfirmationService, public login: LoginService) {
    super(pager);
    this.reservationService.reservationChanged.subscribe(() => this.refresh());
  }

  async refresh() {
    const reservationDTOs = await this.api.getReservations();
    if (!reservationDTOs) {
      this.reservations = undefined;
      return;
    }
    this.reservations = reservationDTOs.map<ReservationTableItem>((dto) => {
      const ti = new ReservationTableItem();
      ti.id = dto.id;
      ti.date = dto.date;
      ti.court = (Number.parseInt(dto.courtId) - Config.courtOffset).toString(10);
      ti.typeCode = dto.typeCode;
      ti.price = dto.price;
      ti.deletionTime = dto.deletionTime;
      ti.note = dto.note;

      let idx = dto.fromTime.lastIndexOf(':');
      ti.from = dto.fromTime.substring(0, idx < 0 ? dto.fromTime.length : idx);

      idx = dto.untilTime.lastIndexOf(':');
      ti.until = dto.untilTime.substring(0, idx < 0 ? dto.fromTime.length : idx);

      return ti;
    }).sort((a, b) => {
      let res = b.date.localeCompare(a.date);
      if (res === 0) {
        res = b.from.localeCompare(a.from);
      }
      if (res === 0) {
        res = b.until.localeCompare(a.until);
      }
      return res;
    });

    this.filterData();
  }

  filterData() {
    let reservationsFiltered = this.filterPipe.transform(this.reservations, 'date', this.dateFilter, this.filterPipe.convertToLocaleDate);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'from', this.fromFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'until', this.untilFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'court', this.courtFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'price', this.priceFilter);
    reservationsFiltered = this.filterPipe.transform(reservationsFiltered, 'note', this.noteFilter);

    this.initPager(reservationsFiltered);
    this.dataFiltered = reservationsFiltered;
    this.paginationChanged(this.currentPage);
  }

  async payReservation(reservation: ReservationTableItem) {
    let url = await this.api.getPaymentUrl(reservation.id);
    window.location.href = url;
  }

  async deleteReservation(reservation: ReservationTableItem) {
    let res = await this.confirmation.presentConfirmationModal();
    if (!res) {
      return;
    }

    await this.api.deleteReservationUnpaid(reservation.id);
    this.reservationService.reservationChanged.emit();
  }
}
