import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tco-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {
  @Input() showInput: boolean;
  inputValue: string;

  constructor(private modal: NgbActiveModal) { }

  onContinue() {
    if (this.showInput) {
      if (this.inputValue) {
        this.modal.close(this.inputValue);
      } else {
        this.modal.close('-');
      }
    } else {
      this.modal.close();
    }
  }

  onCancel() {
    this.modal.dismiss();
  }
}
