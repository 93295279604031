export class ReservationDTO {
    id: string;
    paidByOrderNumber: string;
    userId: string;
    courtId: string;
    date: string;
    dayOfWeek: number;
    fromTime: string;
    untilTime: string;
    typeCode: number;
    userNameFull: string;
    note: string;
    price: number;
    deletionTime: string;
}
