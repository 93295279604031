export class AllReservationTableItem {
    id: string;
    date: string;
    from: string;
    until: string;
    court: string;
    userName: string;
    price: number;
    paidByOrderNumber: string;
}
